header {
	position: sticky;
	top: 0;
	width: 100%;
	padding-top: 30px;
    padding-right: 20px;
	padding-left: 20px;
	margin-right: auto;
	margin-left: auto;
}
header a {
	padding: 40px 0;
	padding-right: 40px;
	text-decoration: none;
	color: #383838;
}
@media (width > 700px) {
	Header {
		width: 650px;
	}
}

@media (prefers-color-scheme: dark) {
	header a {
		color: #f4f5f6;
	}
}
